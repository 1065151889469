import {instance} from "../lib/request";

function list(options) {
    return instance.get('/api/web/series/list', {
        params: options,
    });
}

function get(options) {
    return instance.get('/api/web/series/get', {
        params: options,
    });
}

export default {
    list, get
}
