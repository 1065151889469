import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import api from "../api";
import {Empty, Grid, Spin} from "@arco-design/web-react";
import "./SeriesPage.css";

const Row = Grid.Row;
const Col = Grid.Col;

export default function SeriesPage() {
    const params = useParams();
    const [category, setCategory] = useState([])
    const [list, setList] = useState([])
    const hasMoreRef = useRef(true)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    function GetList() {
        setLoading(true)
        return api.series.list({
            shopId: params.shopId,
        }).then(res => {
            setLoading(false)
            const category = []
            res.category.forEach(n => {
                category.push({
                    id: n.id,
                    name: n.name,
                    list: res.list.filter(m => m.series_category_id === n.id).map(m => {
                        const cover = m.photos[0].fileType === "image" ? m.photos[0].url + '?x-oss-process=image/resize,m_lfit,w_1920/format,webp' : m.photos[0].cover
                        return {
                            ...m,
                            cover: cover
                        }
                    })
                })
            })
            console.log(category)
            setCategory(category)

        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        const mainBody = document.getElementById("main-body");
        mainBody.scrollTop = 0;
        hasMoreRef.current = true
        GetList()
    }, [params.categoryId]);


    function SImage({data}) {
        let url = data.photos[0].url + '?x-oss-process=image/resize,m_lfit,w_1920/format,webp'
        if (data.photos[0].fileType === 'video') {
            url = data.photos[0].cover
        }

        return <div
            style={{
                backgroundImage: 'url(' + url + ')',
                backgroundPosition: '75% 50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '100%'
            }}></div>
    }


    return <Spin loading={loading}>
        <div>
            <div className={"max-w-content"}>
                {
                    category.map((n, idx) => {
                        return <div key={idx} style={{
                            paddingBottom: "40px",
                        }}>
                            <div>
                                <h2 className={"landing-page__category-title"}>{n.name}</h2>
                            </div>
                            <Row gutter={{md: 8, lg: 24, xl: 32}}>
                                {
                                    n.list.map((m, idx) => {
                                        return <Col span={6} key={m.id}>
                                            <div className={"landing-page__series-item"} onClick={() => {
                                                navigate(`/series_detail/${params.shopId}/${m.id}`)
                                            }}>
                                                <SImage data={m}/>
                                                <div className={"landing-page__series-name"}>{m.title}</div>
                                                <div></div>
                                                <div  style={{color:'#eb000e'}}>{m.price ? "￥" + m.price : "点击查看"}</div>
                                            </div>
                                        </Col>
                                    })
                                }
                            </Row>
                        </div>
                    })
                }
            </div>
        </div>
    </Spin>
}
