import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Grid, Image, Menu} from "@arco-design/web-react";
import "./HomePage.css";
import {Outlet, useLoaderData, useNavigate, useParams} from "react-router-dom";
import api from "../api/index";

const MenuItem = Menu.Item;

export async function loader({params}) {
    try {
        const shopInfo = await api.home.get({shopId: params.shopId});
        document.title = shopInfo.shop.name;
        return {
            shopInfo: shopInfo.shop,
            categoryInfo: shopInfo.category,
            shopId: params.shopId,
            seriesCategory: shopInfo.series_category
        };
    } catch (e) {

        return {}
    }
}

// yoIr3
export function HomePage() {
    const {shopInfo, categoryInfo, shopId, seriesCategory} = useLoaderData();
    const navigate = useNavigate();
    const params = useParams();

    if (!shopInfo || !categoryInfo) {
        return <div></div>
    }

    return (
        <div className={"theme-container"}>
            <div className={"theme-3-sidebar"}>
                <div className={"theme-3-header"}>
                    <div className={"sidebar-logo-wrapper"}>
                        <div className={"logo-size--small"}>
                            <img className={"logo__img"}
                                 src={shopInfo.logo}/>
                        </div>
                    </div>
                </div>
                <div className={"theme-3-nav"}>
                    <div
                        className={"block-container block-theme3-nav block-container--flushed-top block-container--flushed-bottom block-container--flushed-left block-container--flushed-right "}>
                        <div className={"sidebar-nav-wrapper"}>
                            <Menu
                                defaultSelectedKeys={[params.categoryId ? params.categoryId : '0_0']}
                                defaultOpenKeys={['0_1']}
                                onClickMenuItem={(key, event, keyPath) => {
                                    if (key === '0_0') {
                                        navigate('/s/' + shopId)
                                    } else if (key.indexOf('c_') === 0) {
                                        navigate(`/category/${shopId}/${key.substring(2)}`);
                                    } else if (key === '0_1') {
                                        navigate(`/series/${shopId}`);
                                    }
                                }}
                            >
                                <MenuItem key='0_0'>首页</MenuItem>
                                <MenuItem key='0_1'>套系</MenuItem>
                                {
                                    categoryInfo.map(n => {
                                        return <MenuItem key={`c_${n.id}`}>{n.name}</MenuItem>
                                    })
                                }
                            </Menu>
                        </div>
                    </div>
                </div>
                <div className={"theme-3-footer theme-3-footer_with-branding"}>
                    <div
                        className={"block-container block-theme3-footer block-container--flushed-top block-container--flushed-bottom block-container--flushed-left block-container--flushed-right "}>
                        <div className={"block-container__block"}>
                            <div className={"sidebar-footer-wrapper"}>
                                <div className={"theme_3_footer-content d-flex flex-column"}>
                                    <div className={"theme_3-footer__business-name"}>
                                        <span>All content Copyright © 2024 {shopInfo.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id={"main-body"} className={"main-body theme-3-main"}>
                <Outlet/>
            </div>
        </div>
    );
}
