import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import api from "../api";
import {Divider, Empty, Grid, Spin} from "@arco-design/web-react";
import "./SeriesDetailPage.css";

const Row = Grid.Row;
const Col = Grid.Col;

export default function SeriesDetailPage() {
    const params = useParams();
    const [info, setInfo] = useState({})
    const hasMoreRef = useRef(true)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    function GetList() {
        setLoading(true)
        return api.series.get({
            id: params.categoryId,
        }).then(res => {
            setLoading(false)
            setInfo(res)
            console.log(res)
        }).catch(e => {
            console.log(e)
        })
    }

    useEffect(() => {
        const mainBody = document.getElementById("main-body");
        mainBody.scrollTop = 0;
        hasMoreRef.current = true
        GetList()
    }, [params.categoryId]);


    function SImage({data}) {
        if (!data.photos) {
            return <div></div>
        }

        let url = data.photos[0].url + '?x-oss-process=image/resize,m_lfit,w_1920/format,webp'
        if (data.photos[0].fileType === 'video') {
            url = data.photos[0].cover
        }

        return <div
            style={{
                backgroundImage: 'url(' + url + ')',
                backgroundPosition: '75% 50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                paddingBottom: '100%'
            }}></div>
    }


    return <Spin loading={loading}>
        <div>
            <div className={"max-w-content"} style={{
                paddingTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
            }}>
                <Row gutter={{md: 8, lg: 24, xl: 32}}>
                    <>
                        <Col span={12} key={info.id}>
                            <SImage data={info}/>
                        </Col>
                        <Col span={12}>
                            <div className={"product-editor__name"}>{info.title}</div>
                            <div style={{fontSize: 15}}>
                                <div style={{color: '#eb000e'}}>{info.price ? "￥" + info.price : "面议"}</div>
                                <div style={{
                                    color: '#d47822',
                                    marginTop: '2px'
                                }}>{info.deposit ? "定金：￥" + info.deposit : ''}</div>
                            </div>
                            <div style={{
                                width: 420,
                                marginTop: 15,
                            }}>
                                <Row gutter={{md: 8, lg: 24, xl: 32}}>
                                    {
                                        info.duration && <Col span={6}>
                                            <div style={{
                                                border: '1px solid rgba(30,30,30,0.1)',
                                                textAlign: 'center',
                                                padding: '10px 0',
                                            }}>
                                                <div style={{fontSize: 14, color: 'rgba(30,30,30,0.6)'}}>拍摄时间</div>
                                                <div>{info.duration}</div>
                                            </div>
                                        </Col>
                                    }
                                    {
                                        info.original_num && <Col span={6}>
                                            <div style={{
                                                border: '1px solid rgba(30,30,30,0.1)',
                                                textAlign: 'center',
                                                padding: '10px 0',
                                            }}>
                                                <div style={{fontSize: 14, color: 'rgba(30,30,30,0.6)'}}>拍摄张数</div>
                                                <div>{info.original_num}</div>
                                            </div>
                                        </Col>
                                    }
                                    {
                                        info.truing_num && <Col span={6}>
                                            <div style={{
                                                border: '1px solid rgba(30,30,30,0.1)',
                                                textAlign: 'center',
                                                padding: '10px 0',
                                            }}>
                                                <div style={{fontSize: 14, color: 'rgba(30,30,30,0.6)'}}>精修张数</div>
                                                <div>{info.truing_num}</div>
                                            </div>
                                        </Col>
                                    }
                                    {
                                        info.is_give_photographic == '1' && <Col span={6}>
                                            <div style={{
                                                border: '1px solid rgba(30,30,30,0.1)',
                                                textAlign: 'center',
                                                padding: '10px 0',
                                            }}>
                                                <div style={{fontSize: 14, color: 'rgba(30,30,30,0.6)'}}>底片全送</div>
                                                <div>是</div>
                                            </div>
                                        </Col>
                                    }
                                    {
                                        info.is_photo_album == '1' && <Col span={6}>
                                            <div style={{
                                                border: '1px solid rgba(30,30,30,0.1)',
                                                textAlign: 'center',
                                                padding: '10px 0',
                                            }}>
                                                <div style={{fontSize: 14, color: 'rgba(30,30,30,0.6)'}}>实体相册</div>
                                                <div>是</div>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                            </div>
                            <Divider/>
                            <div>
                                <div>
                                    <div style={{fontSize: 16}}>服务详情</div>
                                    <pre style={{marginTop: 10}}>{info.service_detail}</pre>
                                </div>
                            </div>
                        </Col>
                    </>
                </Row>
            </div>
        </div>
    </Spin>
}
